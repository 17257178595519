var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex flex-column-fluid" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card card-custom gutter-b bg-transparent shadow-none border-0",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header align-items-center  border-bottom-dark px-0",
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("div", { staticClass: "icons d-flex" }, [
                            _vm.$parent.permissions.includes(
                              "product-brand-manage"
                            )
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn ml-2 p-0 kt_notes_panel_toggle",
                                    attrs: {
                                      "data-toggle": "tooltip",
                                      title: "",
                                      "data-placement": "right",
                                      "data-original-title":
                                        "Check out more demos",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center  rounded-circle shadow-sm ",
                                        on: {
                                          click: function ($event) {
                                            _vm.display_form = !_vm.display_form
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-plus white",
                                            attrs: {
                                              width: "25px",
                                              height: "25px",
                                              viewBox: "0 0 16 16",
                                              fill: "currentColor",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 " }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card card-custom gutter-b bg-white border-0",
                    },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: " table-responsive",
                              attrs: { id: "printableTable" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "dataTables_wrapper no-footer",
                                  attrs: { id: "productbrandTable_wrapper" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dataTables_length",
                                      attrs: { id: "productbrandTable_length" },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "Show\r\n                                                        "
                                        ),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.limit,
                                                expression: "limit",
                                              },
                                            ],
                                            attrs: {
                                              name: "productbrandTable_length",
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.limit = $event.target
                                                    .multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                },
                                                function ($event) {
                                                  return _vm.fetchbrands()
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "10" } },
                                              [_vm._v("10")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "25" } },
                                              [_vm._v("25")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "50" } },
                                              [_vm._v("50")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "100" } },
                                              [_vm._v("100")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "200" } },
                                              [_vm._v("200")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "500" } },
                                              [_vm._v("500")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "1000" } },
                                              [_vm._v("1000")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" entries"),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dataTables_filter",
                                      attrs: { id: "productbrandTable_filter" },
                                    },
                                    [
                                      _c("label", [
                                        _vm._v("Search:"),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.searchParameter,
                                              expression: "searchParameter",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            placeholder: "",
                                          },
                                          domProps: {
                                            value: _vm.searchParameter,
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              return _vm.fetchbrands()
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.searchParameter =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      this.searchParameter != ""
                                        ? _c(
                                            "button",
                                            { on: { click: _vm.clearSearch } },
                                            [_vm._v("clear")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "display dataTable no-footer",
                                      attrs: {
                                        id: "productbrandTable",
                                        role: "grid",
                                      },
                                    },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "text-body" },
                                        [
                                          _c("tr", { attrs: { role: "row" } }, [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "sorting",
                                                class:
                                                  (this.$data.sortType ==
                                                    "asc" ||
                                                    this.$data.sortType ==
                                                      "ASC") &&
                                                  this.$data.sortBy == "id"
                                                    ? "sorting_asc"
                                                    : (this.$data.sortType ==
                                                        "desc" ||
                                                        this.$data.sortType ==
                                                          "DESC") &&
                                                      this.$data.sortBy == "id"
                                                    ? "sorting_desc"
                                                    : "sorting",
                                                staticStyle: {
                                                  width: "31.25px",
                                                },
                                                attrs: {
                                                  tabindex: "0",
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-sort": "ascending",
                                                  "aria-label":
                                                    "ID: activate to sort column descending",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sorting("id")
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\r\n                                                                ID\r\n                                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "sorting",
                                                class:
                                                  (this.$data.sortType ==
                                                    "asc" ||
                                                    this.$data.sortType ==
                                                      "ASC") &&
                                                  this.$data.sortBy == "name"
                                                    ? "sorting_asc"
                                                    : (this.$data.sortType ==
                                                        "desc" ||
                                                        this.$data.sortType ==
                                                          "DESC") &&
                                                      this.$data.sortBy ==
                                                        "name"
                                                    ? "sorting_desc"
                                                    : "sorting",
                                                staticStyle: {
                                                  width: "95.5288px",
                                                },
                                                attrs: {
                                                  tabindex: "0",
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-label":
                                                    "brand: activate to sort column ascending",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sorting("name")
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\r\n                                                                Name\r\n                                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "no-sort sorting_disabled",
                                                staticStyle: {
                                                  width: "53.1891px",
                                                },
                                                attrs: {
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-label": "Action",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\r\n                                                                Slug\r\n                                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "no-sort sorting_disabled",
                                                staticStyle: {
                                                  width: "53.1891px",
                                                },
                                                attrs: {
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-label": "Action",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\r\n                                                                Status\r\n                                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.$parent.permissions.includes(
                                              "product-brand-manage"
                                            )
                                              ? _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "no-sort sorting_disabled",
                                                    staticStyle: {
                                                      width: "53.1891px",
                                                    },
                                                    attrs: {
                                                      rowspan: "1",
                                                      colspan: "1",
                                                      "aria-label": "Action",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\r\n                                                                Action\r\n                                                            "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        {
                                          staticClass:
                                            "kt-table-tbody text-dark",
                                        },
                                        _vm._l(_vm.brands, function (brand) {
                                          return _c(
                                            "tr",
                                            {
                                              key: brand.brand_id,
                                              staticClass:
                                                "kt-table-row kt-table-row-level-0 odd",
                                              attrs: { role: "row" },
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "sorting_1" },
                                                [
                                                  _vm._v(
                                                    "\r\n                                                                " +
                                                      _vm._s(brand.brand_id) +
                                                      "\r\n                                                            "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(brand.brand_name) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(brand.brand_slug) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(brand.brand_status) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.$parent.permissions.includes(
                                                "product-brand-manage"
                                              )
                                                ? _c("td", [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          " click-edit",
                                                        attrs: {
                                                          href: "javascript:void(0)",
                                                          id: "click-edit1",
                                                          "data-toggle":
                                                            "tooltip",
                                                          title: "",
                                                          "data-placement":
                                                            "right",
                                                          "data-original-title":
                                                            "Check out more demos",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editbrand(
                                                              brand
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-edit",
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deletebrand(
                                                              brand.brand_id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-trash",
                                                        }),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        }),
                                        0
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "pagination pagination-sm m-0 float-right",
                                    },
                                    [
                                      _c(
                                        "li",
                                        {
                                          class: [
                                            {
                                              disabled:
                                                !_vm.pagination.prev_page_url,
                                            },
                                          ],
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "page-link",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.fetchbrands(
                                                    _vm.pagination.prev_page_url
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Previous")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("li", { staticClass: "disabled" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link text-dark",
                                            attrs: { href: "#" },
                                          },
                                          [
                                            _vm._v(
                                              "Page " +
                                                _vm._s(
                                                  _vm.pagination.current_page
                                                ) +
                                                " of " +
                                                _vm._s(_vm.pagination.last_page)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          staticClass: "page-item",
                                          class: [
                                            {
                                              disabled:
                                                !_vm.pagination.next_page_url,
                                            },
                                          ],
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "page-link",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.fetchbrands(
                                                    _vm.pagination.next_page_url
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Next")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.display_form
        ? _c(
            "div",
            {
              staticClass:
                "offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel",
              class: _vm.display_form ? "offcanvas-on" : "",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "offcanvas-header d-flex align-items-center justify-content-between pb-3",
                },
                [
                  _c(
                    "h4",
                    { staticClass: "font-size-h4 font-weight-bold m-0" },
                    [_vm._v("Add brand")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.clearForm()
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "bi bi-x",
                          attrs: {
                            width: "20px",
                            height: "20px",
                            viewBox: "0 0 16 16",
                            fill: "currentColor",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("form", { attrs: { id: "myform" } }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "form-group " }, [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("Brand Name"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.brand.brand_name,
                            expression: "brand.brand_name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.brand.brand_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.brand,
                              "brand_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("name")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("name")),
                            },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleImageSelect()
                            },
                          },
                        },
                        [_vm._v("Upload Brand Media")]
                      ),
                      _vm._v(" "),
                      _c(
                        "small",
                        {
                          staticClass: "form-text text-muted",
                          attrs: { id: "textHelp" },
                        },
                        [_vm._v("Select Image file from gallary.")]
                      ),
                      _vm._v(" "),
                      _vm.errors.has("gallary_id")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("gallary_id")),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.gallary_path != ""
                        ? _c("img", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { src: _vm.gallary_path },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Status")]),
                      _vm._v(" "),
                      _c("fieldset", { staticClass: "form-group mb-3" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.brand.brand_status,
                                expression: "brand.brand_status",
                              },
                            ],
                            staticClass:
                              "js-example-basic-single js-states form-control bg-transparent",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.brand,
                                  "brand_status",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("Select Status"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "active" } }, [
                              _vm._v("Active"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "inactive" } }, [
                              _vm._v("Inactive"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors.has("brand_status")
                          ? _c("small", {
                              staticClass: "form-text text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.errors.get("brand_status")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.addUpdatebrand()
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal },
        on: {
          toggleImageSelect: _vm.toggleImageSelect,
          setImage: _vm.setImage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-title mb-0" }, [
      _c("h3", { staticClass: "card-label mb-0 font-weight-bold text-body" }, [
        _vm._v(
          "\r\n                                            Product Brand\r\n                                        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }