<template>
<div class="form-group row">
    <div class="col-md-6">
        <label>Enable / Disable</label>
        <fieldset class="form-group mb-3">
            <select class="form-control" v-model="points.point_setting">
                <option value="enable">Enable</option>
                <option value="disable">Disabled</option>
            </select>
        </fieldset>
    </div>
    <div class="col-md-6">
        <label>Check In Points</label>
        
        <fieldset class="form-group mb-3">
            <input type="number" class="form-control border-dark" placeholder="" v-model="points.checkin_point" ref="checkin_point">
        </fieldset>

    </div>

	 <div class="col-md-6">
        <label>Check In Hours</label>
        <fieldset class="form-group mb-3">
            <input type="number" class="form-control border-dark" placeholder="" v-model="points.checkin_hour" ref="checkin_hour">
        </fieldset>
    </div>

	<div class="col-md-6">
        <label>Per Point Worth</label>
        <fieldset class="form-group mb-3">
            <input type="number" class="form-control border-dark" placeholder="" v-model="points.per_point" ref="per_point">
        </fieldset>
    </div>

	<div class="col-md-6">
        <label>Per Order Price Point</label>
        <fieldset class="form-group mb-3">
            <input type="number" class="form-control border-dark" placeholder="" v-model="points.per_order_price_point" ref="per_order_price_point">
        </fieldset>
    </div>

	<div class="col-md-6">
        <label>Redeem Point</label>
        <fieldset class="form-group mb-3">
            <input type="number" class="form-control border-dark" placeholder="" v-model="points.redeem_point" ref="redeem_point">
        </fieldset>
    </div>

    <div class="col-md-6">
        <label>Register Point</label>
        <fieldset class="form-group mb-3">
            <input type="number" class="form-control border-dark" placeholder="" v-model="points.register_points" ref="redeem_point">
        </fieldset>
    </div>
    

    <div class="col-md-12">
		<br />
        <button @click="updateSetting()" type="submit" class="btn btn-primary">Submit</button>
    </div>

</div>
</template>

<script>
import ErrorHandling from '../../../ErrorHandling'
export default {
    data() {
        return {
            points: {
                checkin_point: "",
                checkin_hour: "",
                per_point: "",
                per_order_price_point: "",
                redeem_point: "",
                register_points:"",
            },
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        };
    },

    methods: {
        fetchSetting() {
            this.$emit('updateLoadingState', true)
            var token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            var responseData = {};

            axios.get('/api/admin/setting?type=point_setting', config)
                .then(res => {
                    for (var i = 0; i < res.data.data.length; i++) {
                        Object.assign(responseData, {
                            [res.data.data[i].setting_key]: res.data.data[i].setting_value
                        });
                    }
                    console.log('response datass', responseData);
                    this.points = responseData;
                })
                .finally(() => (this.$emit('updateLoadingState', false)));
        },

        updateSetting() {
            this.$emit('updateLoadingState', true)
            var points = Object.entries(this.points);
            var key = [];
            var value = [];

            for (var i = 0; i < points.length; i++) {
                key.push(points[i][0]);
                value.push(points[i][1])
            }

            console.log(key, value);

            var token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            axios.post('/api/admin/setting/point_setting', {
                    _method: 'PUT',
                    key,
                    value
                }, config)
                .then(res => {
                    if (res.data.status == "Success") {
						this.$toaster.success('Settings has been updated successfully')
                    }
                  else if(res.data.status == 'Error'){
                        this.$toaster.error(res.data.message)
                    }
                    
                })
                .catch(err => {
                    if(err.response.data.status == 'Error'){
                        this.$toaster.error(err.response.data.message)
                    }
                })
                .finally(() => (this.$emit('updateLoadingState', false)));

        }
    },
    mounted() {
        this.fetchSetting();
    }
};
</script>
