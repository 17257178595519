var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("form", [
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Chose Sale"),
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.sale.sale_id,
                                      expression: "sale.sale_id",
                                    },
                                  ],
                                  staticClass:
                                    "js-states form-control bg-transparent",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.sale,
                                          "sale_id",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.fetchSingleSale()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Select Sale #"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.sales, function (sale) {
                                    return _c(
                                      "option",
                                      { domProps: { value: sale.order_id } },
                                      [
                                        _vm._v(
                                          "\n                              " +
                                            _vm._s(sale.order_id) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.display_sale
                            ? _c("div", { staticClass: "col-md-6" }, [
                                _c("label", { staticClass: "text-body" }, [
                                  _vm._v("Warehouses"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "fieldset",
                                  { staticClass: "form-group mb-3" },
                                  [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(_vm.sale.warehouse_name) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.display_sale
                            ? _c("div", { staticClass: "col-md-6" }, [
                                _c("label", { staticClass: "text-body" }, [
                                  _vm._v("Customers"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "fieldset",
                                  { staticClass: "form-group mb-3" },
                                  [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(
                                          _vm.sale.customer
                                            ? _vm.sale.customer
                                                .customer_first_name +
                                                " " +
                                                _vm.sale.customer
                                                  .customer_last_name
                                            : ""
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.display_sale
                            ? _c("div", { staticClass: "col-md-6" }, [
                                _c("label", { staticClass: "text-body" }, [
                                  _vm._v("Sale Date"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "fieldset",
                                  { staticClass: "form-group mb-3" },
                                  [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(_vm.sale.sale_date) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.display_table
                ? _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card card-custom gutter-b bg-white border-0",
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass: "table table-striped text-body",
                                },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0 header-heading",
                                          attrs: { scope: "col" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                              Name\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0 header-heading",
                                          attrs: { scope: "col" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                              Ordered Quantity\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0 header-heading",
                                          attrs: { scope: "col" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                              New Quantity\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0 header-heading",
                                          attrs: { scope: "col" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                              Price " +
                                              _vm._s(_vm.getCurrencyTitle()) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0 header-heading",
                                          attrs: { scope: "col" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                              Amount " +
                                              _vm._s(_vm.getCurrencyTitle()) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(
                                        _vm.selectedProducts,
                                        function (selectedProduct, index) {
                                          return _c("tr", {}, [
                                            _c("td", [
                                              _c("input", {
                                                attrs: {
                                                  type: "hidden",
                                                  set: (_vm.sale.ordered_qty[
                                                    index
                                                  ] =
                                                    selectedProduct.ordered_qty),
                                                },
                                              }),
                                              _vm._v(
                                                "\n                              " +
                                                  _vm._s(
                                                    selectedProduct.title
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c("input", {
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "Enter Quantity",
                                                    readonly: "",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.sale.ordered_qty[
                                                        index
                                                      ],
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.has(
                                                  "ordered_qty." + index
                                                )
                                                  ? _c("small", {
                                                      staticClass:
                                                        "form-text text-danger",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.errors.get(
                                                            "ordered_qty." +
                                                              index
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.sale.qty[index],
                                                      expression:
                                                        "sale.qty[index]",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "Enter Quantity",
                                                  },
                                                  domProps: {
                                                    value: _vm.sale.qty[index],
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.updateQy(
                                                        $event.target.value,
                                                        index
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.sale.qty,
                                                        index,
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.has(
                                                  "sale_qty." + index
                                                )
                                                  ? _c("small", {
                                                      staticClass:
                                                        "form-text text-danger",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.errors.get(
                                                            "sale_qty." + index
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.sale.price[index],
                                                      expression:
                                                        "sale.price[index]",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: "Enter Price",
                                                    set: (_vm.sale.price[
                                                      index
                                                    ] = selectedProduct.price),
                                                    readonly: "",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.sale.price[index],
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.sale.price,
                                                        index,
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.has("price." + index)
                                                  ? _c("small", {
                                                      staticClass:
                                                        "form-text text-danger",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.errors.get(
                                                            "price." + index
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.sale.product_total[
                                                          index
                                                        ],
                                                      expression:
                                                        "sale.product_total[index]",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    readonly: "",
                                                    placeholder: "Enter Price",
                                                    set: (_vm.sale.product_total[
                                                      index
                                                    ] =
                                                      selectedProduct.payable),
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.sale.product_total[
                                                        index
                                                      ],
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.sale.product_total,
                                                        index,
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              attrs: {
                                                type: "hidden",
                                                set: (_vm.sale.product_id[
                                                  index
                                                ] = selectedProduct.product_id),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              attrs: {
                                                type: "hidden",
                                                set: (_vm.sale.product_name[
                                                  index
                                                ] = selectedProduct.title),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              attrs: {
                                                type: "hidden",
                                                set: (_vm.sale.product_combination_id[
                                                  index
                                                ] =
                                                  selectedProduct.product_combination_id),
                                              },
                                            }),
                                          ])
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("td"),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm.errors.has("sale_qty")
                                            ? _c("small", {
                                                staticClass:
                                                  "form-text text-danger",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.errors.get("sale_qty")
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "3" } }, [
                                          _vm.errors.has("price")
                                            ? _c("small", {
                                                staticClass:
                                                  "form-text text-danger",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.errors.get("price")
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.display_table
                ? _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card card-custom gutter-b bg-white border-0",
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row mb-3" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("label", [_vm._v("Note")]),
                              _vm._v(" "),
                              _c(
                                "fieldset",
                                { staticClass: "form-group mb-3" },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.sale.desc,
                                        expression: "sale.desc",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { height: "130px" },
                                    attrs: {
                                      id: "label-textarea",
                                      rows: "6",
                                      name: "notes",
                                      spellcheck: "false",
                                      readonly: "",
                                    },
                                    domProps: { value: _vm.sale.desc },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.sale,
                                          "desc",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.errors.has("desc")
                                ? _c("small", {
                                    staticClass: "form-text text-danger",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.errors.get("desc")
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row justify-content-end" },
                            [
                              _c("div", { staticClass: "col-12 col-md-3" }, [
                                _c("div", [
                                  _c(
                                    "table",
                                    { staticClass: "table right-table mb-0" },
                                    [
                                      _c("tbody", [
                                        _c(
                                          "tr",
                                          {
                                            staticClass:
                                              "\n                                d-flex\n                                align-items-center\n                                justify-content-between\n                              ",
                                          },
                                          [
                                            _c(
                                              "th",
                                              { staticClass: "border-0" },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "\n                                    font-size-h5\n                                    mb-0\n                                    font-size-bold\n                                    text-dark\n                                  ",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                  SubTotal " +
                                                        _vm._s(
                                                          _vm.getCurrencyTitle()
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "\n                                  border-0\n                                  justify-content-end\n                                  d-flex\n                                  text-black-50\n                                  font-size-base\n                                ",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(_vm.sale.payable) +
                                                    "\n                              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "tr",
                                          {
                                            staticClass:
                                              "\n                                d-flex\n                                align-items-center\n                                justify-content-between\n                              ",
                                          },
                                          [
                                            _c(
                                              "th",
                                              { staticClass: "border-0" },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "\n                                    font-size-h5\n                                    mb-0\n                                    font-size-bold\n                                    text-dark\n                                  ",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                  Discount " +
                                                        _vm._s(
                                                          _vm.getCurrencyTitle()
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "\n                                  border-0\n                                  justify-content-end\n                                  d-flex\n                                  text-black-50\n                                  font-size-base\n                                ",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(_vm.sale.discount) +
                                                    "\n                              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "tr",
                                          {
                                            staticClass:
                                              "\n                                d-flex\n                                align-items-center\n                                justify-content-between\n                              ",
                                          },
                                          [
                                            _c(
                                              "th",
                                              { staticClass: "border-0" },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "\n                                    font-size-h5\n                                    mb-0\n                                    font-size-bold\n                                    text-dark\n                                  ",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                  Total " +
                                                        _vm._s(
                                                          _vm.getCurrencyTitle()
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "\n                                  border-0\n                                  justify-content-end\n                                  d-flex\n                                  text-black-50\n                                  font-size-base\n                                ",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.sale.payable -
                                                        _vm.sale.discount
                                                    ) +
                                                    "\n                              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-12" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.statusUpdate()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                      Submit\n                    "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "\n                  card card-custom\n                  gutter-b\n                  bg-transparent\n                  shadow-none\n                  border-0\n                ",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "\n                    card-header\n                    align-items-center\n                    border-bottom-dark\n                    px-0\n                  ",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [
                      _vm._v(
                        "\n                      Add Sale Return\n                    "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }