var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column-fluid" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "gallary-categories" }, [
            _c(
              "ul",
              {
                staticClass: "nav nav-pills justify-content-start mb-0",
                attrs: { id: "pills-tab", role: "tablist" },
              },
              [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "nav-link btn-light-dark shadow-none mr-4 mb-4",
                      class: { active: _vm.tag_id == "" },
                      attrs: {
                        id: "All-tab-center",
                        "data-toggle": "pill",
                        href: "#All-center",
                        role: "tab",
                        "aria-controls": "All-center",
                        "aria-selected": "true",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.gallaryByTagId("")
                        },
                      },
                    },
                    [_vm._v("\n                All\n              ")]
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.tags, function (tag) {
                  return _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link btn-light-dark shadow-none mr-4 mb-4",
                        class: { active: _vm.tag_id == tag.tag_id },
                        attrs: {
                          id: "general-tab-center",
                          "data-toggle": "pill",
                          href: "#" + tag.tag_name,
                          role: "tab",
                          "aria-controls": "general",
                          "aria-selected": "false",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.gallaryByTagId(tag.tag_id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(tag.tag_name) +
                            "\n              "
                        ),
                      ]
                    ),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              staticClass:
                "gallary0 linked card card-custom gutter-b bg-white border-0",
              attrs: { id: "generalgallary" },
            },
            [
              _c(
                "div",
                { staticClass: "card-header border-0 align-items-center" },
                [
                  _c("fieldset", [
                    _c(
                      "div",
                      { staticClass: "checkbox d-flex align-items-center" },
                      [
                        _c("input", {
                          staticClass: "checkbox-input",
                          attrs: { type: "checkbox", id: "checkbox1" },
                          on: {
                            change: function ($event) {
                              return _vm.check($event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "checkbox1" } }, [
                          _vm._v(
                            "\n                  Select All\n                  "
                          ),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(
                              "(" +
                                _vm._s(this.selectedImages.length) +
                                " Item Selected)"
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icons d-flex" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger p-2 ml-2",
                        attrs: {
                          type: "button",
                          title: "Delete",
                          id: "confirm-color",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deletegallary()
                          },
                        },
                      },
                      [_vm._v("\n                Delete\n              ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary white p-2 ml-2",
                        attrs: {
                          type: "button",
                          title: "Add New",
                          "data-toggle": "modal",
                          "data-target": "#imagepopup",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleModal()
                          },
                        },
                      },
                      [_vm._v("\n                Add New\n              ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "modal fade text-left",
                        class: { show: _vm.showModal },
                        style: [
                          _vm.showModal
                            ? { display: "block !important" }
                            : { display: "none" },
                        ],
                        attrs: {
                          id: "imagepopup",
                          tabindex: "-1",
                          role: "dialog",
                          "aria-labelledby": "myModalLabel1",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "modal-dialog",
                            attrs: { role: "document" },
                          },
                          [
                            _c("div", { staticClass: "modal-content" }, [
                              _c("div", { staticClass: "modal-header" }, [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "modal-title",
                                    attrs: { id: "myModalLabel1" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        Add File Here\n                      "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "\n                          close\n                          rounded-pill\n                          btn btn-sm btn-icon btn-light btn-hover-primary\n                          m-0\n                        ",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                      "aria-label": "Close",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleModal()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "bi bi-x",
                                        attrs: {
                                          width: "20px",
                                          height: "20px",
                                          viewBox: "0 0 16 16",
                                          fill: "currentColor",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "modal-body" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                        Click in the box for upload images (we accept JPG, PNG\n                        & GIF).\n                      "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "avatar-upload mb-3" },
                                  [
                                    _c("div", { staticClass: "avatar-edit" }, [
                                      _c("input", {
                                        attrs: {
                                          type: "file",
                                          id: "imageUpload",
                                          accept: ".png, .jpg, .jpeg",
                                        },
                                        on: { change: _vm.onFileChange },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "imageUpload" } },
                                        [_vm._v(" image upload ")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "avatar-preview" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "rounded",
                                            attrs: { id: "imagePreview" },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "194px",
                                                width: "100%",
                                              },
                                              attrs: { src: _vm.url },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors.has("file")
                                      ? _c("small", {
                                          staticClass: "form-text text-danger",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.errors.get("file")
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "fieldset",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "tags" } }, [
                                      _vm._v("Tags"),
                                    ]),
                                    _vm._v(" "),
                                    _c("input-tag", {
                                      model: {
                                        value: _vm.gallary_tags,
                                        callback: function ($$v) {
                                          _vm.gallary_tags = $$v
                                        },
                                        expression: "gallary_tags",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "modal-footer" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-light",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleModal()
                                      },
                                    },
                                  },
                                  [_c("span", {}, [_vm._v("Close")])]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary ml-1",
                                    attrs: {
                                      type: "button",
                                      "data-dismiss": "modal",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addUpdategallary()
                                      },
                                    },
                                  },
                                  [_c("span", {}, [_vm._v("Submit")])]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-content",
                  attrs: { id: "v-pills-tabContent" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade show active",
                      attrs: {
                        id: "All-center",
                        role: "tabpanel",
                        "aria-labelledby": "All-tab-center",
                      },
                    },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _vm._l(_vm.gallaries, function (gallary) {
                              return _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                      col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3\n                      loadingmore\n                    ",
                                  staticStyle: { display: "block" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setSelectedImages(gallary.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "thumbnail text-center mb-4",
                                      class: {
                                        active: _vm.selectedImages.includes(
                                          gallary.id
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "detail-link" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "\n                            btn btn-link\n                            d-flex\n                            justify-content-center\n                            align-items-center\n                            flex-column\n                          ",
                                              attrs: {
                                                to:
                                                  "/admin/media/" + gallary.id,
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    "xmlns:xlink":
                                                      "http://www.w3.org/1999/xlink",
                                                    version: "1.1",
                                                    x: "0px",
                                                    y: "0px",
                                                    width: "512px",
                                                    height: "512px",
                                                    viewBox: "0 0 512 512",
                                                    "enable-background":
                                                      "new 0 0 512 512",
                                                    "xml:space": "preserve",
                                                  },
                                                },
                                                [
                                                  _c("g", [
                                                    _c("g", [
                                                      _c("g", [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M508.745,246.041c-4.574-6.257-113.558-153.206-252.748-153.206     c-139.191,0-248.179,146.949-252.748,153.2c-4.332,5.936-4.332,13.986,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206     c139.19,0,248.174-146.95,252.748-153.201C513.083,260.027,513.083,251.971,508.745,246.041z M255.997,385.406     c-102.529,0-191.33-97.533-217.617-129.418c26.253-31.913,114.868-129.395,217.617-129.395     c102.524,0,191.319,97.516,217.617,129.418C447.361,287.923,358.746,385.406,255.997,385.406z",
                                                          },
                                                        }),
                                                      ]),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("g", [
                                                      _c("g", [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275     c55.842,0,101.274-45.434,101.274-101.275S311.839,154.725,255.997,154.725z M255.997,323.516     c-37.23,0-67.516-30.287-67.516-67.516c0-37.229,30.287-67.516,67.516-67.516c37.229,0,67.516,30.287,67.516,67.516     C323.513,293.229,293.227,323.516,255.997,323.516z",
                                                          },
                                                        }),
                                                      ]),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                          View Detail\n                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "thumbnail-imges" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "img-select d-block",
                                              attrs: {
                                                href: "javascript:void(0);",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "img-fluid",
                                                attrs: {
                                                  src:
                                                    "/gallary/" +
                                                    gallary.gallary_name,
                                                  alt: "image",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "nav",
                                { attrs: { "aria-label": "navigation" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                          pagination\n                          d-flex\n                          justify-content-end\n                          align-items-center\n                        ",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mr-2 text-dark" },
                                        [
                                          _vm._v(
                                            "\n                          (Showing result\n                          "
                                          ),
                                          _c(
                                            "span",
                                            { attrs: { id: "numbering" } },
                                            [_vm._v(_vm._s(_vm.meta.to))]
                                          ),
                                          _vm._v(
                                            " out of\n                          "
                                          ),
                                          _c(
                                            "span",
                                            { attrs: { id: "totalnumber" } },
                                            [_vm._v(_vm._s(_vm.meta.total))]
                                          ),
                                          _vm._v(
                                            " )\n                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-secondary white",
                                          attrs: { href: "#", id: "loadMore" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setLimit()
                                            },
                                          },
                                        },
                                        [_vm._v("Load More")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom bg-transparent shadow-none border-0 mb-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center border-bottom-dark px-0",
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body",
                    },
                    [_vm._v("\n                Manage Gallery\n              ")]
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("br"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }