var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "clock" }, [
    _c("div", { staticClass: "datetime-content" }, [
      _c("ul", [
        _c("li", { attrs: { id: "hours" } }, [_vm._v(_vm._s(_vm.hours))]),
        _vm._v(" "),
        _c("li", { attrs: { id: "point1" } }, [_vm._v(":")]),
        _vm._v(" "),
        _c("li", { attrs: { id: "min" } }, [_vm._v(_vm._s(_vm.minutes))]),
        _vm._v(" "),
        _c("li", { attrs: { id: "point" } }, [_vm._v(":")]),
        _vm._v(" "),
        _c("li", { attrs: { id: "sec" } }, [_vm._v(_vm._s(_vm.seconds))]),
        _vm._v(" "),
        _c("li", { attrs: { id: "ampm" } }, [_vm._v(_vm._s(_vm.ampm))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "datetime-content" }, [
      _c("div", { attrs: { id: "Date" } }, [_vm._v(_vm._s(_vm.currentdate))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }