var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "notfound" } }, [
    _c(
      "div",
      { staticClass: "notfound" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "The page you are looking for might have been removed had its name changed or is temporarily unavailable."
          ),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/admin/dashboard" } }, [
          _vm._v("home page"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "notfound-404" }, [
      _c("h1", [_vm._v("4"), _c("span", [_vm._v("0")]), _vm._v("4")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }